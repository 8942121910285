<template>
    <section class="moduloBigCard">
        <div class="cont">
            <div class="bigcardCont" v-if="card">
                <CardBig :cover="cover" :titolo="modulo.translations && modulo.translations[0] ? modulo.translations[0].titolo : undefined" :sottotitolo="modulo.translations && modulo.translations[0] ? modulo.translations[0].sottotitolo : undefined" :link="link.address" :isExternal="link.external" />
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
const props = defineProps<{ modulo: any }>()
const { getCardCover, getCardLink } = useCard()

const card = computed(() => {
    if (props.modulo.card && props.modulo.card.length > 0 && props.modulo.card[0].item) return props.modulo.card[0]
    else return null
})

const cover = computed(() => card.value ? getCardCover(card.value) : null)
const link = computed(() => card.value ? getCardLink(card.value, card.value.item.__typename) : { address: null, external: false })
</script>

<style lang="scss" scoped>
.moduloBigCard {

    .bigcardCont {
        position: relative;
        height: 400px;
        width: 100%;
    }
}
</style>